import ReactGA from 'react-ga';

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

export const Header = label => {
  Event('Header', 'Visit ' + label);
};

export const Portfolio = (type, label) => {
  Event(`Portfolio [${label}]`, 'View ' + type, label);
};

export const Skill = action => {
  Event('Skill', action);
};
