import reactLogo from '../img/react.png';
import reduxLogo from '../img/redux.png';
import html5Logo from '../img/html5.png';
import css3Logo from '../img/css3.png';
import javascriptLogo from '../img/js.png';
import typescriptLogo from '../img/ts.png';

export const DATE = {
	201609: 0,
	201610: 1,
	201611: 2,
	201612: 3,
	201701: 4,
	201702: 5,
	201703: 6,
	201704: 7,
	201705: 8,
	201706: 9,
	201707: 10,
	201708: 11,
	201709: 12,
	201710: 13,
	201711: 14,
	201712: 15,
	201801: 16,
	201802: 17,
	201803: 18,
	201804: 19,
	201805: 20,
	201806: 21,
	201807: 22,
	201808: 23,
	201809: 24,
	201810: 25,
	201811: 26,
	201812: 27,
	201901: 28,
	201902: 29,
	201903: 30,
	201904: 31,
	201905: 32,
	201906: 33,
	201907: 34,
	201908: 35,
	201909: 36,
	201910: 37,
	201911: 38,
	201912: 39,
	202001: 40,
	202002: 41,
	202003: 42,
	202004: 43,
	202005: 44,
	202006: 45,
	202007: 46,
	202008: 47,
	202009: 48,
	202010: 49,
	202011: 50,
	202012: 51,
	202101: 52,
	202102: 53,
	202103: 54,
	202104: 55,
	202105: 56,
	202106: 57,
	202107: 58,
	202108: 59,
	202109: 60,
	202110: 61,
	202111: 62,
	202112: 63,
	202201: 64,
	202202: 65,
	202203: 66,
	202204: 67,
	202205: 68,
	202206: 69,
	202207: 70,
	202208: 71,
	202209: 72,
	202210: 73,
	202211: 74,
	202212: 75,
	202301: 76,
	202302: 77,
	202303: 78,
	202304: 79,
	202305: 80,
	202306: 81,
	202307: 82,
	202308: 83,
	202309: 84,
	202310: 85,
	202311: 86,
	202312: 87,
};

export const DATE_FROM_INDEX = Object.keys(DATE);

export const YEAR_WIDTH = () => {
	let map = {};
	let result = {};
	DATE_FROM_INDEX.forEach((date) => {
		let year = date.substring(0, 4);
		if (map[year]) {
			map[year] = map[year] + 1;
		} else {
			map[year] = 1;
		}
	});
	let min = '';
	let sum = 0;
	for (let key in map) {
		result[key] = Math.floor(map[key] / DATE_FROM_INDEX.length * 100);
		min = min ? (result[min] > result[key] ? key : min) : key;
		sum += result[key];
	}
	if (sum !== 100) {
		result[min] += 100 - sum;
	}
	return result;
};

export const MONTH = [ '', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];

export const logos = {
	reactLogo: '../img/react.png',
	reduxLogo: '../img/redux.png',
	html5Logo: '../img/html5.png',
	css3Logo: '../img/css3.png',
	javascriptLogo: '../img/js.png',
	typescriptLogo: '../img/ts.png',
	flutterLogo: '../img/flutter.png'
};

export const reactProgress = {
	[DATE[201811]]: 0,
	[DATE[201812]]: 40,
	[DATE[201901]]: 75,
	[DATE[201902]]: 85,
	[DATE[201903]]: 75,
	[DATE[201904]]: 75,
	[DATE[201905]]: 90,
	[DATE[201906]]: 92,
	[DATE[201907]]: 94,
	[DATE[201908]]: 96,
	[DATE[201909]]: 97,
	[DATE[201910]]: 98,
	[DATE[201911]]: 99,
	[DATE[201912]]: 100,
	[DATE[202001]]: 104,
	[DATE[202002]]: 108,
	[DATE[202003]]: 112,
	[DATE[202004]]: 116,
	[DATE[202005]]: 120,
	[DATE[202006]]: 124,
	[DATE[202007]]: 127,
	[DATE[202008]]: 130,
	[DATE[202009]]: 130,
	[DATE[202010]]: 130,
	[DATE[202011]]: 130,
	[DATE[202012]]: 140,
	[DATE[202101]]: 140,
	[DATE[202102]]: 140,
	[DATE[202103]]: 140,
	[DATE[202104]]: 145,
	[DATE[202105]]: 145,
	[DATE[202106]]: 145,
	[DATE[202107]]: 150,
	[DATE[202108]]: 150,
	[DATE[202109]]: 150,
	[DATE[202110]]: 155,
	[DATE[202111]]: 155,
	[DATE[202112]]: 155,
	[DATE[202201]]: 160,
	[DATE[202202]]: 165,
	[DATE[202203]]: 180,
	[DATE[202204]]: 181,
	[DATE[202205]]: 182,
	[DATE[202206]]: 183,
	[DATE[202207]]: 183,
	[DATE[202208]]: 183,
	[DATE[202209]]: 183,
	[DATE[202210]]: 183,
	[DATE[202211]]: 183,
	[DATE[202212]]: 183,
	[DATE[202301]]: 183,
	[DATE[202302]]: 184,
	[DATE[202303]]: 185,
	[DATE[202304]]: 186,
	[DATE[202305]]: 187,
	[DATE[202306]]: 188,
	[DATE[202307]]: 189,
	[DATE[202308]]: 190,
	[DATE[202309]]: 191,
	[DATE[202310]]: 192,
	[DATE[202311]]: 193,
	[DATE[202312]]: 194,
};

export const reduxProgress = {
	[DATE[201812]]: 0,
	[DATE[201901]]: 30,
	[DATE[201902]]: 70,
	[DATE[201903]]: 60,
	[DATE[201904]]: 61,
	[DATE[201905]]: 62,
	[DATE[201906]]: 75,
	[DATE[201907]]: 80,
	[DATE[201908]]: 80,
	[DATE[201909]]: 80,
	[DATE[201910]]: 95,
	[DATE[201911]]: 97,
	[DATE[201912]]: 100,
	[DATE[202001]]: 104,
	[DATE[202002]]: 108,
	[DATE[202003]]: 112,
	[DATE[202004]]: 116,
	[DATE[202005]]: 120,
	[DATE[202006]]: 124,
	[DATE[202007]]: 127,
	[DATE[202008]]: 130,
	[DATE[202009]]: 130,
	[DATE[202010]]: 130,
	[DATE[202011]]: 130,
	[DATE[202012]]: 130,
	[DATE[202101]]: 130,
	[DATE[202102]]: 130,
	[DATE[202103]]: 130,
	[DATE[202104]]: 130,
	[DATE[202105]]: 130,
	[DATE[202106]]: 130,
	[DATE[202107]]: 130,
	[DATE[202108]]: 130,
	[DATE[202109]]: 130,
	[DATE[202110]]: 130,
	[DATE[202111]]: 130,
	[DATE[202112]]: 130,
	[DATE[202201]]: 130,
	[DATE[202202]]: 130,
	[DATE[202203]]: 130,
	[DATE[202204]]: 130,
	[DATE[202205]]: 130,
	[DATE[202206]]: 130,
	[DATE[202207]]: 130,
	[DATE[202208]]: 130,
	[DATE[202209]]: 130,
	[DATE[202210]]: 130,
	[DATE[202211]]: 130,
	[DATE[202212]]: 130,
	[DATE[202301]]: 131,
	[DATE[202302]]: 132,
	[DATE[202303]]: 133,
	[DATE[202304]]: 134,
	[DATE[202305]]: 135,
	[DATE[202306]]: 136,
	[DATE[202307]]: 137,
	[DATE[202308]]: 138,
	[DATE[202309]]: 139,
	[DATE[202310]]: 140,
	[DATE[202311]]: 141,
	[DATE[202312]]: 142
};

export const javascriptProgress = {
	[DATE[201703]]: 0,
	[DATE[201704]]: 1,
	[DATE[201705]]: 2,
	[DATE[201706]]: 5,
	[DATE[201707]]: 0,
	[DATE[201804]]: 0,
	[DATE[201805]]: 20,
	[DATE[201806]]: 25,
	[DATE[201807]]: 30,
	[DATE[201808]]: 0,
	[DATE[201809]]: 0,
	[DATE[201810]]: 10,
	[DATE[201811]]: 35,
	[DATE[201812]]: 70,
	[DATE[201901]]: 80,
	[DATE[201902]]: 85,
	[DATE[201903]]: 87,
	[DATE[201904]]: 90,
	[DATE[201905]]: 91,
	[DATE[201906]]: 92,
	[DATE[201907]]: 94,
	[DATE[201908]]: 96,
	[DATE[201909]]: 97,
	[DATE[201910]]: 98,
	[DATE[201911]]: 99,
	[DATE[201912]]: 100,
	[DATE[202001]]: 102,
	[DATE[202002]]: 104,
	[DATE[202003]]: 106,
	[DATE[202004]]: 108,
	[DATE[202005]]: 110,
	[DATE[202006]]: 112,
	[DATE[202007]]: 115,
	[DATE[202008]]: 120,
	[DATE[202009]]: 120,
	[DATE[202010]]: 120,
	[DATE[202011]]: 120,
	[DATE[202012]]: 120,
	[DATE[202101]]: 120,
	[DATE[202102]]: 120,
	[DATE[202103]]: 120,
	[DATE[202104]]: 120,
	[DATE[202105]]: 120,
	[DATE[202106]]: 120,
	[DATE[202107]]: 120,
	[DATE[202108]]: 120,
	[DATE[202109]]: 120,
	[DATE[202110]]: 120,
	[DATE[202111]]: 120,
	[DATE[202112]]: 120,
	[DATE[202201]]: 120,
	[DATE[202202]]: 120,
	[DATE[202203]]: 120,
	[DATE[202204]]: 120,
	[DATE[202205]]: 120,
	[DATE[202206]]: 120,
	[DATE[202207]]: 120,
	[DATE[202208]]: 120,
	[DATE[202209]]: 120,
	[DATE[202210]]: 120,
	[DATE[202211]]: 120,
	[DATE[202212]]: 120,
	[DATE[202201]]: 120,
	[DATE[202202]]: 120,
	[DATE[202203]]: 120,
	[DATE[202204]]: 120,
	[DATE[202205]]: 120,
	[DATE[202206]]: 120,
	[DATE[202207]]: 120,
	[DATE[202208]]: 120,
	[DATE[202209]]: 120,
	[DATE[202210]]: 120,
	[DATE[202211]]: 120,
	[DATE[202212]]: 120,
	[DATE[202201]]: 120,
	[DATE[202202]]: 120,
	[DATE[202203]]: 120,
	[DATE[202204]]: 120,
	[DATE[202205]]: 120,
	[DATE[202206]]: 120,
	[DATE[202207]]: 120,
	[DATE[202208]]: 120,
	[DATE[202209]]: 120,
	[DATE[202210]]: 120,
	[DATE[202211]]: 120,
	[DATE[202212]]: 120,
	[DATE[202301]]: 121,
	[DATE[202302]]: 122,
	[DATE[202303]]: 123,
	[DATE[202304]]: 124,
	[DATE[202305]]: 125,
	[DATE[202306]]: 126,
	[DATE[202307]]: 127,
	[DATE[202308]]: 128,
	[DATE[202309]]: 129,
	[DATE[202310]]: 130,
	[DATE[202311]]: 131,
	[DATE[202312]]: 132
};

export const html5Progress = {
	[DATE[201609]]: 0,
	[DATE[201610]]: 75,
	[DATE[201611]]: 77,
	[DATE[201612]]: 79,
	[DATE[201701]]: 81,
	[DATE[201702]]: 82,
	[DATE[201703]]: 83,
	[DATE[201704]]: 84,
	[DATE[201705]]: 85,
	[DATE[201706]]: 86,
	[DATE[201707]]: 85,
	[DATE[201708]]: 86,
	[DATE[201709]]: 85,
	[DATE[201710]]: 86,
	[DATE[201711]]: 85,
	[DATE[201712]]: 86,
	[DATE[201801]]: 85,
	[DATE[201802]]: 86,
	[DATE[201803]]: 85,
	[DATE[201804]]: 86,
	[DATE[201805]]: 85,
	[DATE[201806]]: 86,
	[DATE[201807]]: 85,
	[DATE[201808]]: 86,
	[DATE[201809]]: 85,
	[DATE[201810]]: 86,
	[DATE[201811]]: 85,
	[DATE[201812]]: 86,
	[DATE[201901]]: 85,
	[DATE[201902]]: 86,
	[DATE[201903]]: 85,
	[DATE[201904]]: 86,
	[DATE[201905]]: 85,
	[DATE[201906]]: 86,
	[DATE[201907]]: 85,
	[DATE[201908]]: 86,
	[DATE[201909]]: 85,
	[DATE[201910]]: 86,
	[DATE[201911]]: 85,
	[DATE[201912]]: 86,
	[DATE[202001]]: 86,
	[DATE[202002]]: 86,
	[DATE[202003]]: 86,
	[DATE[202004]]: 86,
	[DATE[202005]]: 86,
	[DATE[202006]]: 86,
	[DATE[202007]]: 86,
	[DATE[202008]]: 86,
	[DATE[202009]]: 86,
	[DATE[202010]]: 86,
	[DATE[202011]]: 86,
	[DATE[202012]]: 86,
	[DATE[202101]]: 86,
	[DATE[202102]]: 86,
	[DATE[202103]]: 86,
	[DATE[202104]]: 86,
	[DATE[202105]]: 86,
	[DATE[202106]]: 86,
	[DATE[202107]]: 86,
	[DATE[202108]]: 86,
	[DATE[202109]]: 86,
	[DATE[202110]]: 86,
	[DATE[202111]]: 86,
	[DATE[202112]]: 86,
	[DATE[202201]]: 86,
	[DATE[202202]]: 86,
	[DATE[202203]]: 86,
	[DATE[202204]]: 86,
	[DATE[202205]]: 86,
	[DATE[202206]]: 86,
	[DATE[202207]]: 86,
	[DATE[202208]]: 86,
	[DATE[202209]]: 86,
	[DATE[202210]]: 86,
	[DATE[202211]]: 86,
	[DATE[202212]]: 86,
	[DATE[202301]]: 86,
	[DATE[202302]]: 86,
	[DATE[202303]]: 86,
	[DATE[202304]]: 86,
	[DATE[202305]]: 86,
	[DATE[202306]]: 86,
	[DATE[202307]]: 86,
	[DATE[202308]]: 86,
	[DATE[202309]]: 86,
	[DATE[202310]]: 86,
	[DATE[202311]]: 86,
	[DATE[202312]]: 86
};

export const css3Progress = {
	[DATE[201609]]: 0,
	[DATE[201610]]: 75,
	[DATE[201611]]: 77,
	[DATE[201612]]: 79,
	[DATE[201701]]: 81,
	[DATE[201702]]: 82,
	[DATE[201703]]: 83,
	[DATE[201704]]: 84,
	[DATE[201705]]: 86,
	[DATE[201706]]: 85,
	[DATE[201707]]: 86,
	[DATE[201708]]: 85,
	[DATE[201709]]: 86,
	[DATE[201710]]: 85,
	[DATE[201711]]: 86,
	[DATE[201712]]: 85,
	[DATE[201801]]: 86,
	[DATE[201802]]: 85,
	[DATE[201803]]: 86,
	[DATE[201804]]: 85,
	[DATE[201805]]: 86,
	[DATE[201806]]: 85,
	[DATE[201807]]: 86,
	[DATE[201808]]: 85,
	[DATE[201809]]: 86,
	[DATE[201810]]: 85,
	[DATE[201811]]: 86,
	[DATE[201812]]: 85,
	[DATE[201901]]: 86,
	[DATE[201902]]: 85,
	[DATE[201903]]: 86,
	[DATE[201904]]: 85,
	[DATE[201905]]: 86,
	[DATE[201906]]: 85,
	[DATE[201907]]: 86,
	[DATE[201908]]: 85,
	[DATE[201909]]: 85,
	[DATE[201910]]: 90,
	[DATE[201911]]: 90,
	[DATE[201912]]: 90,
	[DATE[202001]]: 90,
	[DATE[202002]]: 90,
	[DATE[202003]]: 90,
	[DATE[202004]]: 90,
	[DATE[202005]]: 90,
	[DATE[202006]]: 90,
	[DATE[202007]]: 90,
	[DATE[202008]]: 90,
	[DATE[202009]]: 90,
	[DATE[202010]]: 90,
	[DATE[202011]]: 90,
	[DATE[202012]]: 110,
	[DATE[202101]]: 110,
	[DATE[202102]]: 110,
	[DATE[202103]]: 110,
	[DATE[202104]]: 110,
	[DATE[202105]]: 110,
	[DATE[202106]]: 110,
	[DATE[202107]]: 110,
	[DATE[202108]]: 110,
	[DATE[202109]]: 110,
	[DATE[202110]]: 110,
	[DATE[202111]]: 110,
	[DATE[202112]]: 110,
	[DATE[202201]]: 110,
	[DATE[202202]]: 110,
	[DATE[202203]]: 110,
	[DATE[202204]]: 110,
	[DATE[202205]]: 110,
	[DATE[202206]]: 110,
	[DATE[202207]]: 110,
	[DATE[202208]]: 110,
	[DATE[202209]]: 110,
	[DATE[202210]]: 110,
	[DATE[202211]]: 110,
	[DATE[202212]]: 110,
	[DATE[202301]]: 110,
	[DATE[202302]]: 110,
	[DATE[202303]]: 110,
	[DATE[202304]]: 110,
	[DATE[202305]]: 110,
	[DATE[202306]]: 110,
	[DATE[202307]]: 110,
	[DATE[202308]]: 110,
	[DATE[202309]]: 110,
	[DATE[202310]]: 110,
	[DATE[202311]]: 110,
	[DATE[202312]]: 110
};

export const typescriptProgress = {
	[DATE[201904]]: 0,
	[DATE[201905]]: 30,
	[DATE[201906]]: 40,
	[DATE[201907]]: 40,
	[DATE[201908]]: 0,
	[DATE[201909]]: 0,
	[DATE[201910]]: 0,
	[DATE[201911]]: 0,
	[DATE[201912]]: 0,
	[DATE[202001]]: 0,
	[DATE[202002]]: 20,
	[DATE[202003]]: 20,
	[DATE[202004]]: 20,
	[DATE[202005]]: 20,
	[DATE[202006]]: 10,
	[DATE[202007]]: 10,
	[DATE[202008]]: 10,
	[DATE[202009]]: 10,
	[DATE[202010]]: 30,
	[DATE[202011]]: 10,
	[DATE[202012]]: 10,
	[DATE[202101]]: 10,
	[DATE[202102]]: 10,
	[DATE[202103]]: 10,
	[DATE[202104]]: 10,
	[DATE[202105]]: 10,
	[DATE[202106]]: 10,
	[DATE[202107]]: 10,
	[DATE[202108]]: 10,
	[DATE[202109]]: 10,
	[DATE[202110]]: 10,
	[DATE[202111]]: 10,
	[DATE[202112]]: 10,
	[DATE[202201]]: 10,
	[DATE[202202]]: 10,
	[DATE[202203]]: 10,
	[DATE[202204]]: 10,
	[DATE[202205]]: 20,
	[DATE[202206]]: 30,
	[DATE[202207]]: 20,
	[DATE[202208]]: 10,
	[DATE[202209]]: 10,
	[DATE[202210]]: 10,
	[DATE[202211]]: 10,
	[DATE[202212]]: 10,
	[DATE[202301]]: 30,
	[DATE[202302]]: 50,
	[DATE[202303]]: 60,
	[DATE[202304]]: 80,
	[DATE[202305]]: 90,
	[DATE[202306]]: 91,
	[DATE[202307]]: 92,
	[DATE[202308]]: 93,
	[DATE[202309]]: 94,
	[DATE[202310]]: 95,
	[DATE[202311]]: 96,
	[DATE[202312]]: 97
};

export const skillData = [
	{
		name: 'React',
		logo: reactLogo,
		logoLong: true,
		progress: reactProgress,
		fullValue: 0 || reactProgress[Object.values(DATE)[DATE_FROM_INDEX.length - 1]],
	},
	{
		name: 'Redux',
		logo: reduxLogo,
		logoLong: false,
		progress: reduxProgress,
		fullValue:  0 || reduxProgress[Object.values(DATE)[DATE_FROM_INDEX.length - 1]],
	},
	{
		name: 'JavaScript',
		logo: javascriptLogo,
		logoLong: false,
		progress: javascriptProgress,
		fullValue:  0 || javascriptProgress[Object.values(DATE)[DATE_FROM_INDEX.length - 1]],
	},
	{
		name: 'HTML5',
		logo: html5Logo,
		logoLong: false,
		progress: html5Progress,
		fullValue: 100 || html5Progress[Object.values(DATE)[DATE_FROM_INDEX.length - 1]],
	},
	{
		name: 'CSS3',
		logo: css3Logo,
		logoLong: true,
		progress: css3Progress,
		fullValue: 130 || css3Progress[Object.values(DATE)[DATE_FROM_INDEX.length - 1]],
	},
	{
		name: 'TypeScript',
		logo: typescriptLogo,
		logoLong: false,
		progress: typescriptProgress,
		fullValue: 110 || typescriptProgress[Object.values(DATE)[DATE_FROM_INDEX.length - 1]],
	}
];
